<template>
  <div class="patient-insurance">
    <span>Patient's Insurance</span>
    <form
      v-if="patientInsuranceLoading"
      class="spinner"
    >
      <LoadingSpinner />
    </form>
    <form v-else>
      <fieldset>
        <legend>Primary</legend>
        <div>
          <label for="primary_provider_name">Insurance Provider</label>
          <input
            v-model="formData.primary_provider_name"
            type="text"
            name="insurance-provider-primary"
          >
          <span class="error">{{ formErrors.primary_provider_name }}</span>
        </div>
        <div>
          <label for="primary_member_id">Member ID</label>
          <input
            v-model="formData.primary_member_id"
            type="text"
            name="member-id-primary"
          >
          <span class="error">{{ formErrors.primary_member_id }}</span>
        </div>
        <div>
          <label for="primary_group_id">Group ID</label>
          <input
            v-model="formData.primary_group_id"
            type="text"
            name="group-id-primary"
          >
          <span class="error">{{ formErrors.primary_group_id }}</span>
        </div>
      </fieldset>
      <fieldset>
        <legend>Secondary</legend>
        <div>
          <label for="secondary_provider_name">Insurance Provider</label>
          <input
            v-model="formData.secondary_provider_name"
            type="text"
            name="insurance-provider-secondary"
          >
          <span class="error">{{ formErrors.secondary_provider_name }}</span>
        </div>
        <div>
          <label for="secondary_member_id">Member ID</label>
          <input
            v-model="formData.secondary_member_id"
            type="text"
            name="member-id-secondary"
          >
          <span class="error">{{ formErrors.secondary_member_id }}</span>
        </div>
        <div>
          <label for="secondary_group_id">Group ID</label>
          <input
            v-model="formData.secondary_group_id"
            type="text"
            name="group-id-secondary"
          >
          <span class="error">{{ formErrors.secondary_group_id }}</span>
        </div>
      </fieldset>
    </form>
    <div class="datetime-container">
      <p v-if="primaryLastModifiedDatetime">
        Primary insurance last modified: {{ primaryLastModifiedDatetime }}
      </p>
      <p v-if="secondaryLastModifiedDatetime">
        Secondary insurance last modified: {{ secondaryLastModifiedDatetime }}
      </p>
    </div>
    <div
      v-show="showButtons"
      class="button-container"
    >
      <!-- Cancel (secondary button) -->
      <owl-button
        label="Cancel"
        size="small"
        variant="secondary"
        @on-click="cancelChanges"
      />
      <!-- Confirm/Submit (primary button) -->
      <owl-button
        label="Confirm"
        size="small"
        :variant="primaryVariant"
        :disabled="submitDisabled"
        @on-click="submitForm"
      />
    </div>
  </div>
</template>

<style>

</style>
<script>
import { OwlButton } from 'owl-components'
import LoadingSpinner from '@/components/common/LoadingSpinner'
import { Helpers } from '@/mixins/Helpers'
import { DateTimeHelper } from '@/mixins/DateTimeHelper'
import { mapActions, mapGetters } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import { GET_PATIENT_INSURANCE, SET_PATIENT_INSURANCE } from '@/store/modules/patients/constants.js'

export default {
  name: 'ClinicianPatientInsurance',
  components: {
    OwlButton,
    LoadingSpinner
  },
  mixins: [Helpers, DateTimeHelper],
  data () {
    return {
      ready: false,
      dateFormat: 'MMM DD, YYYY hh:mm A z', // 'Jun 06, 2022 06:00 AM'
      formData: {
        patient_user_id: 0,
        primary_group_id: '',
        primary_last_modified_datetime: '',
        primary_member_id: '',
        primary_provider_name: '',
        secondary_group_id: '',
        secondary_last_modified_datetime: '',
        secondary_member_id: '',
        secondary_provider_name: ''
      },
      formErrors: {
        primary_group_id: '',
        primary_member_id: '',
        primary_provider_name: '',
        secondary_group_id: '',
        secondary_member_id: '',
        secondary_provider_name: ''
      },
      showButtons: false,
      submitDisabled: true,
      formIsValid: false
    }
  },
  computed: {
    ...mapGetters({
      activeAssignment: 'getActiveAssignment',
      patientInsurance: 'getPatientInsurance',
      patientInsuranceLoading: 'getPatientInsuranceLoading'
    }),
    primaryLastModifiedDatetime () {
      return this.formData.primary_last_modified_datetime ? this.$toLocal(this.formData.primary_last_modified_datetime, this.dateFormat) : ''
    },
    secondaryLastModifiedDatetime () {
      return this.formData.secondary_last_modified_datetime ? this.$toLocal(this.formData.secondary_last_modified_datetime, this.dateFormat) : ''
    },
    primaryVariant () {
      return this.submitDisabled ? 'disabled' : 'primary'
    }
  },
  watch: {
    formData: {
      immediate: true,
      deep: true,
      handler (newVal) {
        if (this.ready) {
          this.errorHandling(newVal)
        }
      }
    }
  },
  created () {
    this.fetchInsuranceData()
  },
  methods: {
    ...mapActions([
      GET_PATIENT_INSURANCE,
      SET_PATIENT_INSURANCE
    ]),
    fetchInsuranceData () {
      this.$store.dispatch('GET_PATIENT_INSURANCE', this.activeAssignment.patient_id).then(() => {
        this.formData = cloneDeep(this.patientInsurance.insurance)
        this.$nextTick(() => {
          this.ready = true
        })
      })
    },
    errorHandling (value) {
      this.resetFormErrors()
      this.showButtons = true
      this.submitDisabled = false

      // check for spaces
      const regSpace = /\s/g
      if (value.primary_group_id.length > 0 && value.primary_group_id.search(regSpace) !== -1) {
        this.formErrors.primary_group_id = 'Spaces are not allowed'
      }
      if (value.primary_member_id.length > 0 && value.primary_member_id.search(regSpace) !== -1) {
        this.formErrors.primary_member_id = 'Spaces are not allowed'
      }
      if (value.secondary_group_id.length > 0 && value.secondary_group_id.search(regSpace) !== -1) {
        this.formErrors.secondary_group_id = 'Spaces are not allowed'
      }
      if (value.secondary_member_id.length > 0 && value.secondary_member_id.search(regSpace) !== -1) {
        this.formErrors.secondary_member_id = 'Spaces are not allowed'
      }

      // Insurance provider is required
      if (value.primary_provider_name.length < 1 && value.primary_member_id.length > 0) {
        this.formErrors.primary_provider_name = 'Insurance provider is required'
      }
      if (value.secondary_provider_name.length < 1 && value.secondary_member_id.length > 0) {
        this.formErrors.secondary_provider_name = 'Insurance provider is required'
      }
      if (value.primary_provider_name.length < 1 && value.primary_group_id.length > 0) {
        this.formErrors.primary_provider_name = 'Insurance provider is required'
      }
      if (value.secondary_provider_name.length < 1 && value.secondary_group_id.length > 0) {
        this.formErrors.secondary_provider_name = 'Insurance provider is required'
      }

      // Member ID is required
      if (value.primary_member_id.length < 1 && value.primary_provider_name.length > 0) {
        this.formErrors.primary_member_id = 'Member ID is required'
      }
      if (value.secondary_member_id.length < 1 && value.secondary_provider_name.length > 0) {
        this.formErrors.secondary_member_id = 'Member ID is required'
      }
      if (value.primary_member_id.length < 1 && value.primary_group_id.length > 0) {
        this.formErrors.primary_member_id = 'Member ID is required'
      }
      if (value.secondary_member_id.length < 1 && value.secondary_group_id.length > 0) {
        this.formErrors.secondary_member_id = 'Member ID is required'
      }

      if (this.formHasNoErrors(this.formErrors)) {
        this.formIsValid = true
      } else {
        this.submitDisabled = true
      }
    },
    submitForm () {
      if (this.formIsValid) {
        this.submitDisabled = true
        const loader = this.$loading.show()
        const mappedFormData = {
          patient_user_id: this.activeAssignment.patient_id,
          primary_provider_name: this.formData.primary_provider_name,
          primary_member_id: this.formData.primary_member_id,
          primary_group_id: this.formData.primary_group_id,
          secondary_provider_name: this.formData.secondary_provider_name,
          secondary_group_id: this.formData.secondary_group_id,
          secondary_member_id: this.formData.secondary_member_id
        }
        this.$store.dispatch('SET_PATIENT_INSURANCE', mappedFormData)
          .then((response) => {
            if (response && response.status === 200) {
              this.$toast.success({ message: 'Insurance has been saved' })
              // get modified dates and hide buttons here on successful save
              this.ready = false
              this.showButtons = false
              this.resetFormErrors()
              this.fetchInsuranceData()
            } else {
              this.$toast.error({ message: 'There was a problem saving insurance' }) // error msg here
            }
          }).finally(() => {
            loader.hide()
            this.submitDisabled = false
          })
      }
    },
    cancelChanges () {
      this.ready = false
      this.showButtons = false
      this.formData = cloneDeep(this.patientInsurance.insurance)
      this.resetFormErrors()
      this.$nextTick().then(() => {
        this.ready = true
      })
    },
    resetFormErrors () {
      this.formErrors = {
        primary_group_id: '',
        primary_member_id: '',
        primary_provider_name: '',
        secondary_group_id: '',
        secondary_member_id: '',
        secondary_provider_name: ''
      }
    },
    formHasNoErrors (o) {
      return Object.values(o).every(i => i === '' || i === null || i === undefined)
    }
  }
}
</script>
<style lang="scss">
.patient-insurance {
  @apply tw-py-5 tw-pl-7 tw-text-left tw-bg-white;
  span {
    @apply tw-text-base;
  }
  form {
    @apply tw-flex tw-py-1;
    fieldset {
      @apply tw-justify-start tw-text-sm tw-uppercase tw-pr-8 tw-gap-x-48;
      // width: 45%;
      legend {
        @apply tw-text-sm tw-font-bold;
        font-family: "MuseoSans_700";
      }
      div {
        @apply tw-pt-3 tw-pb-9;
        .error {
          @apply tw-text-sm tw-text-owl-alert-orange-strong tw-h-8 tw-mt-2 tw-normal-case;
        }
      }
      label {
        @apply tw-w-full tw-font-light;
        font-family: "MuseoSans_300";
      }
      input {
        @apply tw-w-72 tw-h-8 tw-p-1 tw-pl-1.5 tw-block tw-border tw-border-owl-gray-border tw-text-lg tw-font-normal tw-ring-0 tw-outline-none;
        font-family: "MuseoSans_300";
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        &:focus {
          box-shadow: 0px 5px 5px rgb(40 175 229 / 25%);
        }
      }
    }

    &.spinner, &.spinner div {
      @apply tw-h-full tw-w-full tw-text-center
    }
  }
  .datetime-container {
    p {
      @apply tw-w-full tw-font-light tw-mb-0 tw-pb-2 tw-normal-case;
      font-family: "MuseoSans_300";
    }
  }
  .button-container {
    @apply tw-flex tw-justify-end tw-pt-4 tw-pr-7;
    width: 640px;
    button {
      @apply tw-mr-1 tw-text-base tw-uppercase;
      font-family: "MuseoSans_500";
      padding: 6px 28px !important;
    }
  }
}
</style>
